import React from "react";
import BecomeDealerForm from "../components/becomeDealer/becomeDealerForm";
import BecomeDealerMain from "../components/becomeDealer/becomeDealerMain"
import BecomeDealerRequisite from "../components/becomeDealer/becomeDealerRequisite"
import Layout from "../components/Global/layout"
import SEO from "../components/seo/SEO"

const BecomeDealer = () => {
  return (
    <>
    <SEO />
      <Layout>
        <main className="contact-options-grid mt-10">
          <BecomeDealerMain />
          <BecomeDealerRequisite />
          <BecomeDealerForm />
        </main>
      </Layout>
    </>
  );
};

export default BecomeDealer;