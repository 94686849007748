import React from "react";

const BecomeDealerRequisite = () => {
  return (
    <>
      <div className="bg-gray-900 p-2"></div>
      <ul className="flex flex-col space-y-8 bg-gray-900 px-4 pt-4 md:px-8 md:pt-8 lg:px-12 lg:pt-12">
        <ul className="flex flex-col space-y-3">
          <li>
            <h3 className="text-3xl leading-normal text-gray-300 font-medium">
              What does it take to become a Dealer?
            </h3>
          </li>
          <li>
            <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
              Do you have it in you to become a successful Kabira Mobility
              Dealer?
            </p>
          </li>
          <li>
            <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
              Please check the pre-requisites below in order to become a Kabira
              Mobility Dealer
            </p>
          </li>
        </ul>
        <div className="grid grid-cols-1 sm:grid-cols-[1fr,8px,1fr] lg:grid-cols-[1fr,8px,1fr,8px,1fr] bg-gray-700 gap-[1px] border-t-[1px] border-gray-700 -mx-4 md:-mx-8 lg:-mx-12">
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <path d="M13.66,7C13.1,5.82,11.9,5,10.5,5L6,5V3h12v2l-3.26,0c0.48,0.58,0.84,1.26,1.05,2L18,7v2l-2.02,0c-0.25,2.8-2.61,5-5.48,5 H9.77l6.73,7h-2.77L7,14v-2h3.5c1.76,0,3.22-1.3,3.46-3L6,9V7L13.66,7z" />
                </g>
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <h4 className="become-dealer-requisite-title">Investment</h4>
              </li>
              <li>
                <p className="become-dealer-requisite-description">
                  ₹17.5 Lacs
                </p>
              </li>
            </ul>
          </div>
          <div className="py-2 bg-gray-900 hidden sm:block"></div>
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g />
                <g>
                  <path d="M21.9,8.89l-1.05-4.37c-0.22-0.9-1-1.52-1.91-1.52H5.05C4.15,3,3.36,3.63,3.15,4.52L2.1,8.89 c-0.24,1.02-0.02,2.06,0.62,2.88C2.8,11.88,2.91,11.96,3,12.06V19c0,1.1,0.9,2,2,2h14c1.1,0,2-0.9,2-2v-6.94 c0.09-0.09,0.2-0.18,0.28-0.28C21.92,10.96,22.15,9.91,21.9,8.89z M18.91,4.99l1.05,4.37c0.1,0.42,0.01,0.84-0.25,1.17 C19.57,10.71,19.27,11,18.77,11c-0.61,0-1.14-0.49-1.21-1.14L16.98,5L18.91,4.99z M13,5h1.96l0.54,4.52 c0.05,0.39-0.07,0.78-0.33,1.07C14.95,10.85,14.63,11,14.22,11C13.55,11,13,10.41,13,9.69V5z M8.49,9.52L9.04,5H11v4.69 C11,10.41,10.45,11,9.71,11c-0.34,0-0.65-0.15-0.89-0.41C8.57,10.3,8.45,9.91,8.49,9.52z M4.04,9.36L5.05,5h1.97L6.44,9.86 C6.36,10.51,5.84,11,5.23,11c-0.49,0-0.8-0.29-0.93-0.47C4.03,10.21,3.94,9.78,4.04,9.36z M5,19v-6.03C5.08,12.98,5.15,13,5.23,13 c0.87,0,1.66-0.36,2.24-0.95c0.6,0.6,1.4,0.95,2.31,0.95c0.87,0,1.65-0.36,2.23-0.93c0.59,0.57,1.39,0.93,2.29,0.93 c0.84,0,1.64-0.35,2.24-0.95c0.58,0.59,1.37,0.95,2.24,0.95c0.08,0,0.15-0.02,0.23-0.03V19H5z" />
                </g>
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <h4 className="become-dealer-requisite-title">Area</h4>
              </li>
              <li>
                <p className="become-dealer-requisite-description">
                  &gt;1000ft<sup>2</sup>
                </p>
              </li>
            </ul>
          </div>
          <div className="py-2 bg-gray-900 hidden lg:block"></div>
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <path d="M19,7c0-1.1-0.9-2-2-2h-3v2h3v2.65L13.52,14H10V9H6c-2.21,0-4,1.79-4,4v3h2c0,1.66,1.34,3,3,3s3-1.34,3-3h4.48L19,10.35V7 z M4,14v-1c0-1.1,0.9-2,2-2h2v3H4z M7,17c-0.55,0-1-0.45-1-1h2C8,16.55,7.55,17,7,17z" />
                  <rect height="2" width="5" x="5" y="6" />
                  <path d="M19,13c-1.66,0-3,1.34-3,3s1.34,3,3,3s3-1.34,3-3S20.66,13,19,13z M19,17c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1 S19.55,17,19,17z" />
                </g>
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <h4 className="become-dealer-requisite-title">Products</h4>
              </li>
              <li>
                <p className="become-dealer-requisite-description">
                  11 Vehicles
                </p>
              </li>
            </ul>
          </div>
          <div className="py-2 bg-gray-900 hidden sm:block lg:hidden"></div>
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <rect fill="none" height="24" width="24" />
              <g>
                <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,17c0,0.55-0.45,1-1,1H7c-0.55,0-1-0.45-1-1l0-0.61 c0-1.18,0.68-2.26,1.76-2.73C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C2,12.1,2.9,13,4,13z M5.13,14.1C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43L0,17 c0,0.55,0.45,1,1,1l3.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2 C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1 c0.4,0.68,0.63,1.46,0.63,2.29V18l3.5,0c0.55,0,1-0.45,1-1L24,16.43z M12,6c1.66,0,3,1.34,3,3c0,1.66-1.34,3-3,3s-3-1.34-3-3 C9,7.34,10.34,6,12,6z" />
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <h4 className="become-dealer-requisite-title">Team</h4>
              </li>
              <li>
                <p className="become-dealer-requisite-description">04 People</p>
              </li>
            </ul>
          </div>
          <div className="py-2 bg-gray-900 hidden lg:block"></div>
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <rect fill="none" height="24" width="24" />
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g>
                  <g>
                    <path d="M21.67,18.17l-4.72-4.72c-0.48-0.48-0.99-0.59-1.58-0.59l-2.54,2.54c0,0.59,0.11,1.11,0.59,1.58l4.72,4.72 c0.39,0.39,1.02,0.39,1.41,0l2.12-2.12C22.06,19.2,22.06,18.56,21.67,18.17z" />
                  </g>
                  <g>
                    <path d="M16.63,9.49c0.39,0.39,1.02,0.39,1.41,0l0.71-0.71l2.12,2.12c1.17-1.17,1.17-3.07,0-4.24l-2.83-2.83 c-0.39-0.39-1.02-0.39-1.41,0l-0.71,0.71V2c0-0.62-0.76-0.95-1.21-0.5l-2.54,2.54c-0.45,0.45-0.12,1.21,0.5,1.21h2.54l-0.71,0.71 c-0.39,0.39-0.39,1.02,0,1.41l0.35,0.35l-2.89,2.89L7.85,6.48v-1c0-0.27-0.11-0.52-0.29-0.71L5.54,2.74 c-0.39-0.39-1.02-0.39-1.41,0L2.71,4.16c-0.39,0.39-0.39,1.02,0,1.41L4.73,7.6c0.19,0.19,0.44,0.29,0.71,0.29h1l4.13,4.13 l-0.85,0.85H8.42c-0.53,0-1.04,0.21-1.41,0.59l-4.72,4.72c-0.39,0.39-0.39,1.02,0,1.41l2.12,2.12c0.39,0.39,1.02,0.39,1.41,0 l4.72-4.72c0.38-0.38,0.59-0.88,0.59-1.41v-1.29l5.15-5.15L16.63,9.49z" />
                  </g>
                </g>
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <h4 className="become-dealer-requisite-single-title">
                  Standard Workshop
                </h4>
              </li>
            </ul>
          </div>
          <div className="py-2 bg-gray-900 hidden sm:block"></div>
          <div className=" become-dealer-requisite-main-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enable-background="new 0 0 24 24"
              className="svg-icon-become-dealer"
              viewBox="0 0 24 24"
              width="24px"
              fill="#000000"
            >
              <g>
                <rect fill="none" height="24" width="24" />
                <rect fill="none" height="24" width="24" />
              </g>
              <g>
                <g />
                <g>
                  <path d="M16.24,11.51l1.57-1.57l-3.75-3.75l-1.57,1.57L8.35,3.63c-0.78-0.78-2.05-0.78-2.83,0l-1.9,1.9 c-0.78,0.78-0.78,2.05,0,2.83l4.13,4.13L3.15,17.1C3.05,17.2,3,17.32,3,17.46v3.04C3,20.78,3.22,21,3.5,21h3.04 c0.13,0,0.26-0.05,0.35-0.15l4.62-4.62l4.13,4.13c1.32,1.32,2.76,0.07,2.83,0l1.9-1.9c0.78-0.78,0.78-2.05,0-2.83L16.24,11.51z M9.18,11.07L5.04,6.94l1.89-1.9c0,0,0,0,0,0l1.27,1.27L7.73,6.8c-0.39,0.39-0.39,1.02,0,1.41c0.39,0.39,1.02,0.39,1.41,0 l0.48-0.48l1.45,1.45L9.18,11.07z M17.06,18.96l-4.13-4.13l1.9-1.9l1.45,1.45l-0.48,0.48c-0.39,0.39-0.39,1.02,0,1.41 c0.39,0.39,1.02,0.39,1.41,0l0.48-0.48l1.27,1.27L17.06,18.96z" />
                  <path d="M20.71,7.04c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34c-0.47-0.47-1.12-0.29-1.41,0l-1.83,1.83l3.75,3.75L20.71,7.04z" />
                </g>
              </g>
            </svg>
            <ul className="become-dealer-requisite-container">
              <li>
                <p className="become-dealer-requisite-single-title">
                  Standard Branding
                </p>
              </li>
            </ul>
          </div>
        </div>
      </ul>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
      <div className="bg-gray-900 p-2"></div>
    </>
  );
};

export default BecomeDealerRequisite;
