import React from 'react'

const BecomeDealerMain = () => {
    return (
        <>
         <div className="bg-gray-900 p-2"></div>
          <ul className="flex flex-col space-y-3 bg-gray-900 p-4 md:p-8 lg:p-12">
            <li>
              <h1 className="text-2xl text-blue-500 font-semibold">
                Become a Dealer
              </h1>
            </li>
            <li>
              <h2 className="text-3xl leading-normal text-gray-300 font-medium">
                The Future of Mobility is Electric.
                <br /> Join Hands Today.
              </h2>
            </li>
            <li>
              <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
                Kabira Mobility is one of the Fastest Growing Electric Vehicle
                Start-up based out at Verna, Goa. 
              </p>
            </li>
            <li>
              <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
                Kabira Mobility is actively expanding its footprint across
                India, we are looking forward to partnering with entrepreneurs
                who share our Vision and are interested in being a part of the
                Future.
              </p>
            </li>
            <li>
              <p className="text-base leading-relaxed text-gray-400 font-light md:pr-32 lg:pr-56">
                Interested in becoming the part of something great? Fill out the
                Form below to get Started.
              </p>
            </li>
          </ul>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>
          <div className="bg-gray-900 p-2"></div>   
        </>
    )
}

export default BecomeDealerMain
